import {
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAIL,
  GET_ORDER_BY_KEY,
  GET_ORDER_BY_KEY_SUCCESS,
  GET_ORDER_BY_KEY_FAIL,
  APPROVE_ORDER,
  APPROVE_ORDER_SUCCESS,
  APPROVE_ORDER_FAIL,
  ASSIGN_WORK,
  ASSIGN_WORK_SUCCESS,
  ASSIGN_WORK_FAIL,
  START_WORK,
  START_WORK_SUCCESS,
  START_WORK_FAIL,
  END_WORK,
  END_WORK_SUCCESS,
  END_WORK_FAIL,
  REVIEW_WORK,
  REVIEW_WORK_SUCCESS,
  REVIEW_WORK_FAIL,
  REJECT_ORDER,
  REJECT_ORDER_SUCCESS,
  REJECT_ORDER_FAIL,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAIL,
  DELETE_IMAGE,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAIL,
  ADD_COMMENT_TO_ORDER,
  ADD_COMMENT_TO_ORDER_SUCCESS,
  ADD_COMMENT_TO_ORDER_FAIL,
  ORDER_FILTER_SEARCH_BY_ID_NUM,
  DELAY_LOADING,
} from './actionTypes'

/* eslint-disable no-unused-vars */

const initialState = {
  isLoading: false,
  newOrders: [],
  inProgressOrders: [],
  inReviewOrders: [],
  completedOrders: [],
  financeData: [],
  orderDetail: null,
  error: {},
}

const order = (state = initialState, action) => {
  switch (action.type) {
    case DELAY_LOADING:
      console.log(
        'reducer::DELAY_LOADING action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break
    case ADD_COMMENT_TO_ORDER:
      console.log(
        'reducer::ADD_COMMENT_TO_ORDER action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case ADD_COMMENT_TO_ORDER_SUCCESS:
      console.log(
        'reducer::ADD_COMMENT_TO_ORDER_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: false,
      }
      break

    case ADD_COMMENT_TO_ORDER_FAIL:
      console.log(
        'reducer::ADD_COMMENT_TO_ORDER_FAIL action.payload=' + action.payload
      )
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case UPLOAD_IMAGE:
      console.log(
        'reducer::UPLOAD_IMAGE action.payload=' + JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case UPLOAD_IMAGE_SUCCESS:
      console.log(
        'reducer::UPLOAD_IMAGE_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: false,
      }
      break

    case UPLOAD_IMAGE_FAIL:
      console.log('reducer::UPLOAD_IMAGE_FAIL action.payload=' + action.payload)
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case DELETE_IMAGE:
      console.log(
        'reducer::DELETE_IMAGE action.payload=' + JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case DELETE_IMAGE_SUCCESS:
      console.log(
        'reducer::DELETE_IMAGE_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: false,
      }
      break

    case DELETE_IMAGE_FAIL:
      console.log('reducer::DELETE_IMAGE_FAIL action.payload=' + action.payload)
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case REVIEW_WORK:
      console.log(
        'reducer::REVIEW_WORK action.payload=' + JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case REVIEW_WORK_SUCCESS:
      console.log(
        'reducer::REVIEW_WORK_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: false,
      }
      break

    case REVIEW_WORK_FAIL:
      console.log('reducer::REVIEW_WORK_FAIL action.payload=' + action.payload)
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case END_WORK:
      console.log(
        'reducer::END_WORK action.payload=' + JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case END_WORK_SUCCESS:
      console.log(
        'reducer::END_WORK_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: false,
      }
      break

    case END_WORK_FAIL:
      console.log('reducer::END_WORK_FAIL action.payload=' + action.payload)
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case START_WORK:
      console.log(
        'reducer::START_WORK action.payload=' + JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case START_WORK_SUCCESS:
      console.log(
        'reducer::START_WORK_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: false,
      }
      break

    case START_WORK_FAIL:
      console.log('reducer::START_WORK_FAIL action.payload=' + action.payload)
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case ASSIGN_WORK:
      console.log(
        'reducer::ASSIGN_WORK action.payload=' + JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case ASSIGN_WORK_SUCCESS:
      console.log(
        'reducer::ASSIGN_WORK_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: false,
      }
      break

    case ASSIGN_WORK_FAIL:
      console.log('reducer::ASSIGN_WORK_FAIL action.payload=' + action.payload)
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case APPROVE_ORDER:
      console.log(
        'reducer::APPROVE_ORDER action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case APPROVE_ORDER_SUCCESS:
      console.log(
        'reducer::APPROVE_ORDER_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: false,
      }
      break

    case APPROVE_ORDER_FAIL:
      console.log(
        'reducer::APPROVE_ORDER_FAIL action.payload=' + action.payload
      )
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case REJECT_ORDER:
      console.log(
        'reducer::REJECT_ORDER action.payload=' + JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case REJECT_ORDER_SUCCESS:
      console.log(
        'reducer::REJECT_ORDER_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: false,
      }
      break

    case REJECT_ORDER_FAIL:
      console.log('reducer::REJECT_ORDER_FAIL action.payload=' + action.payload)
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case GET_ORDER_BY_KEY:
      console.log(
        'reducer::GET_ORDER_BY_KEY action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        orderDetail: null,
        isLoading: true,
      }
      break

    case GET_ORDER_BY_KEY_SUCCESS:
      console.log(
        'reducer::GET_ORDER_BY_KEY_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      //In vehicle order history, filter out imcomplete orders and sort by date
      var orderDet = action.payload.order.serviceordersnapshot
      orderDet.vehicle.order = orderDet.vehicle.order.filter(
        (ord) => ord.status == 'Completed' || ord.status == 'WorkReviewed'
      )
      orderDet.vehicle.order = orderDet.vehicle.order.sort((a, b) =>
        a.timestamp < b.timestamp ? 1 : -1
      )

      state = {
        ...state,
        isLoading: false,
        orderDetail: orderDet,
      }
      break

    case GET_ORDER_BY_KEY_FAIL:
      console.log(
        'reducer::GET_ORDER_BY_KEY_FAIL action.payload=' + action.payload
      )
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case GET_ALL_ORDERS:
      console.log('reducer::GET_ALL_ORDERS action.payload=' + action.payload)

      state = {
        ...state,
        newOrders: [],
        inProgressOrders: [],
        inReviewOrders: [],
        completedOrders: [],
        error: {},
        isLoading: true,
      }
      break

    case GET_ALL_ORDERS_SUCCESS:
      console.log(
        'reducer::GET_ALL_ORDERS_SUCCESS action.payload=' + action.payload
      )

      var completedOrder = []
      var inProgressOrders = []
      var inReviewOrders = []
      var newOrder = []

      var orderDetail = null
      action.payload.serviceordersnapshot.forEach((item) => {
        if (state.orderDetail && state.orderDetail.data_key == item.data_key) {
          orderDetail = item
        }

        item['isFilterOut'] = false
        if (
          item.status == 'Completed' ||
          item.status == 'WorkReviewed' ||
          item.status == 'Rejected'
        ) {
          completedOrder.push(item)
        } else if (
          item.status == 'WorkStarted' ||
          item.status == 'WorkRejected'
        ) {
          inProgressOrders.push(item)
        } else if (
          item.status == 'New' ||
          item.status == 'WorkAssigned' ||
          item.status == 'Approved'
        ) {
          newOrder.push(item)
        } else if (item.status == 'WorkEnded') {
          inReviewOrders.push(item)
        }
      })

      if (orderDetail != null) {
        console.log('***********************************')
        console.log(orderDetail)
        console.log('***********************************')
      }

      state = {
        ...state,
        orderDetail: orderDetail,
        isLoading: false,
        newOrders: newOrder.sort((a, b) => {
          if (a.pickup_date_time > b.pickup_date_time) {
            return 1
          }
          if (a.pickup_date_time < b.pickup_date_time) {
            return -1
          }
          if (a.priority > b.priority) {
            return 1
          }
          if (a.priority < b.priority) {
            return -1
          }
        }),
        inProgressOrders: inProgressOrders.sort((a, b) => {
          if (a.pickup_date_time > b.pickup_date_time) {
            return 1
          }
          if (a.pickup_date_time < b.pickup_date_time) {
            return -1
          }
          if (a.priority > b.priority) {
            return 1
          }
          if (a.priority < b.priority) {
            return -1
          }
        }),
        inReviewOrders: inReviewOrders.sort((a, b) => {
          if (a.pickup_date_time > b.pickup_date_time) {
            return 1
          }
          if (a.pickup_date_time < b.pickup_date_time) {
            return -1
          }
          if (a.priority > b.priority) {
            return 1
          }
          if (a.priority < b.priority) {
            return -1
          }
        }),
        completedOrders: completedOrder.sort((a, b) => {
          if (a.data_timestamp < b.data_timestamp) {
            return 1
          }
          if (a.data_timestamp > b.data_timestamp) {
            return -1
          }
        }),
        financeData: completedOrder.sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1
        ),
      }
      break

    case GET_ALL_ORDERS_FAIL:
      console.log(
        'reducer::GET_ALL_ORDERS_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case ORDER_FILTER_SEARCH_BY_ID_NUM:
      console.log(
        'reducer::ORDER_FILTER_SEARCH_BY_ID_NUM action.payload=' +
          action.payload.idNumber
      )

      var newOrdersFilteredWithIdNum = state.newOrders.map((item) => item)
      var progOrdersFilteredWithIdNum = state.inProgressOrders.map(
        (item) => item
      )
      var reviewOrdersFilteredWithIdNum = state.inReviewOrders.map(
        (item) => item
      )

      if (action.payload.idNumber.length == 0) {
        newOrdersFilteredWithIdNum.forEach((curr) => (curr.isFilterOut = false))
        progOrdersFilteredWithIdNum.forEach(
          (curr) => (curr.isFilterOut = false)
        )
        reviewOrdersFilteredWithIdNum.forEach(
          (curr) => (curr.isFilterOut = false)
        )
      } else {
        newOrdersFilteredWithIdNum.forEach((curr) =>
          curr.vehicle.data_key.startsWith(action.payload.idNumber) ||
          curr.stock.startsWith(action.payload.idNumber) ||
          curr.customer_tag.startsWith(action.payload.idNumber)
            ? (curr.isFilterOut = false)
            : (curr.isFilterOut = true)
        )
        progOrdersFilteredWithIdNum.forEach((curr) =>
          curr.vehicle.data_key.startsWith(action.payload.idNumber) ||
          curr.stock.startsWith(action.payload.idNumber) ||
          curr.customer_tag.startsWith(action.payload.idNumber)
            ? (curr.isFilterOut = false)
            : (curr.isFilterOut = true)
        )
        reviewOrdersFilteredWithIdNum.forEach((curr) =>
          curr.vehicle.data_key.startsWith(action.payload.idNumber) ||
          curr.stock.startsWith(action.payload.idNumber) ||
          curr.customer_tag.startsWith(action.payload.idNumber)
            ? (curr.isFilterOut = false)
            : (curr.isFilterOut = true)
        )
      }

      state = {
        ...state,
        isLoading: false,
        orderIdSearch: action.payload.idNumber,
        newOrders: newOrdersFilteredWithIdNum,
        inProgressOrders: progOrdersFilteredWithIdNum,
        inReviewOrders: reviewOrdersFilteredWithIdNum,
      }
      break

    default:
      break
  }

  return state
}

export default order
