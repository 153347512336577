/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { Row, Col, Modal, Button, Label, Media, Table } from 'reactstrap'

import { approveOrder, rejectNewOrder } from 'store/actions'

import GlistenOrderSummary from '../../../components/GlistenOrderSummary'

import './approve.scss'
import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'
import nocarimage from '../../../assets/images/no-car-image.svg'
import { sendPageViewEvent } from '../../../firebase'
import { formatDate, isToday, isLate } from '../../../helpers/date_formatter'

const ApproveCfrmModal = (props) => {
  useEffect(() => {
    sendPageViewEvent('Approve Order Submission Modal')
  }, [])

  const priorityLabel = (idx) => {
    switch (idx) {
      case '1':
        return props.t('WAITER - ')
      case '2':
        return props.t('SPOT DELIVERY - ')
      case '3':
        return props.t('EOD - ')
      case '4':
        return props.t('FILL - ')
      case '5':
        return props.t('APPOINTMENT - ')
      default:
        return ''
    }
  }

  const dueTomrrwMorn = (due_date) => {
    var tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (
      tomorrow.getDate() == due_date.getDate() &&
      tomorrow.getMonth() == due_date.getMonth() &&
      tomorrow.getFullYear() == due_date.getFullYear()
    ) {
      if (due_date.getHours() == 10) {
        if (due_date.getMinutes() <= 29) {
          return true
        } else {
          return false
        }
      } else if (due_date.getHours() < 10) {
        return true
      }
    } else {
      return false
    }
  }

  // Checks if order is due today OR before 10:30AM the following day OR is currently late
  const isDueSoon = (pickup_date) => {
    if (
      isToday(pickup_date) ||
      dueTomrrwMorn(pickup_date) ||
      isLate(pickup_date)
    ) {
      return true
    } else {
      return false
    }
  }

  const confirmButton = () => {
    return (
      <Row className='row-margin-fix'>
        <Col
          lg='6'
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0rem',
          }}
        >
          {props.order && (
            <Button
              className='sec-modal-btn btn glisten-btn'
              style={{ marginBottom: '1.125rem' }}
              onClick={() => {
                props.onRejectOrder(
                  props.org,
                  props.tenant,
                  props.order.data_key
                )
                props.showModalApprove(false)
              }}
            >
              {props.t('REJECT')}
            </Button>
          )}
        </Col>
        <Col
          lg='6'
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0rem',
          }}
        >
          {props.order && props.userFeat.includes('ftl-dealership') && (
            <Button
              className='prim-modal-btn btn glisten-btn'
              style={{ marginBottom: '1.125rem' }}
              onClick={() => {
                props.onApproveOrder(
                  props.org,
                  props.tenant,
                  props.order.data_key
                )
                props.showModalApprove(false)
              }}
            >
              {props.t('APPROVE')}
            </Button>
          )}
        </Col>
      </Row>
    )
  }

  return (
    <Modal
      scrollable={true}
      size='xl'
      isOpen={props.isOpenApprove}
      toggle={() => {
        props.showModalApprove(false)
      }}
      centered={true}
    >
      {/* HEADER */}
      <div className='modal-header glisten-modal-header'>
        <div>
          <h3 className='glisten-modal-title'>{props.title}</h3>
        </div>

        <button
          className='close-btn'
          onClick={() => {
            props.showModalApprove(false)
          }}
        >
          <Logo />
        </button>
      </div>

      {/* BODY */}
      <div
        className='modal-body glisten-modal-body'
        style={{ paddingTop: '1.188rem' }}
      >
        <Row className='row-margin-fix'>
          <Col
            lg='7'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Row className='row-margin-fix'>
              {/* YEAR + MAKE */}
              <Col xl='12'>
                {props.order && props.order.vehicle ? (
                  <Label
                    className='vehicle-info-label-alt'
                    style={{
                      marginTop: '2.125rem',
                      marginBottom: '0rem',
                      fontWeight: '600',
                    }}
                  >
                    {props.order.vehicle.year} {props.order.vehicle.make}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>
              {/* MODEL */}
              <Col xl='12'>
                {props.order && props.order.vehicle ? (
                  <Label
                    className='vehicle-info-label-alt'
                    style={{
                      marginBottom: '0rem',
                      fontWeight: '600',
                    }}
                  >
                    {props.order.vehicle.model}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>

              {/* COLOR */}
              <Col xl='12'>
                {props.order && props.order.vehicle ? (
                  <Label
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '200',
                      fontSize: '1.125rem',
                      letterSpacing: '0.127rem',
                      color: '#333333',
                      marginTop: '1rem',
                      marginBottom: '0rem',
                    }}
                  >
                    {props.t('Color')}
                    {'\u003A'} {props.order.vehicle.color}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>
            </Row>

            {/* VEHICLE IDENTIFIER TABLE */}
            <Row className='row-margin-fix'>
              <Col xl='10'>
                <Table
                  striped
                  style={{ marginTop: '2.813rem', marginBottom: '0rem' }}
                >
                  <tbody>
                    <tr style={{ backgroundColor: '#EEEEEE' }}>
                      <td className='thin-table-cell'>{props.t('VIN')}</td>
                      <td
                        className='thin-table-cell'
                        style={{ fontWeight: '200', textAlign: 'right' }}
                      >
                        {props.order == null
                          ? null
                          : props.order.vehicle.data_key}
                      </td>
                    </tr>
                    <tr>
                      <td className='thin-table-cell'>
                        {props.t('Stock Number')}
                      </td>
                      <td
                        className='thin-table-cell'
                        style={{ fontWeight: '200', textAlign: 'right' }}
                      >
                        {props.order == null
                          ? props.t('N/A')
                          : props.order.stock}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: '#EEEEEE' }}>
                      <td className='thin-table-cell'>
                        {props.t('PO Number')}
                      </td>
                      <td
                        className='thin-table-cell'
                        style={{ fontWeight: '200', textAlign: 'right' }}
                      >
                        {props.order == null
                          ? props.t('N/A')
                          : props.order.po_number}
                      </td>
                    </tr>
                    <tr>
                      <td className='thin-table-cell'>
                        {props.t('Customer Tag')}
                      </td>
                      <td
                        className='thin-table-cell'
                        style={{ fontWeight: '200', textAlign: 'right' }}
                      >
                        {props.order == null
                          ? props.t('N/A')
                          : props.order.customer_tag}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            {/* DELIVERY PRIORITY + DATE */}
            <Row className='row-margin-fix'>
              <Col
                lg='10'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                <Label
                  className={
                    props.order &&
                    isDueSoon(new Date(props.order.pickup_date_time * 1000))
                      ? 'confirm-red-status-bg'
                      : 'confirm-gray-status-bg'
                  }
                >
                  {props.order && priorityLabel(props.order.priority)}
                  {props.order &&
                    formatDate(new Date(props.order.pickup_date_time))}
                </Label>
              </Col>
            </Row>
          </Col>
          {/* CAR IMAGE */}
          <Col
            lg='5'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Media
              src={nocarimage}
              style={{
                display: 'block',
                maxWidth: '100%',
                height: 'auto',
                width: '100%',
                borderRadius: '8px',
                marginTop: '1rem',
              }}
            ></Media>
          </Col>
        </Row>

        <Row className='row-margin-fix'>
          <Col
            lg='6'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            {props.order && props.order.service_catalog ? (
              <GlistenOrderSummary items={props.order.service_catalog} />
            ) : (
              <></>
            )}
          </Col>
        </Row>

        {props.order && props.order.note ? (
          <Row className='row-margin-fix'>
            {/* ORDER NOTE */}
            <Col
              lg='10'
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '0.625rem',
                paddingRight: '0rem',
                marginTop: '0.25rem',
                marginBottom: '6.25rem',
              }}
            >
              <Label className='order-title'>
                {props.t('Note')}
                {'\u003A'}{' '}
              </Label>
              <Label className='order-title'>{props.order.note}</Label>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </div>

      {/* FOOTER */}
      <div className='modal-footer glisten-modal-footer'>
        <div>
          {confirmButton()}
          {/* <Button
            className='btn glisten-dbtn alt-mod-action'
            // onClick={(evt) => {
            //   handleMoreDetails(evt)
            // }}
          >
            {props.secondBtnTitle}
          </Button> */}
        </div>
      </div>
    </Modal>
  )
}

ApproveCfrmModal.propTypes = {
  t: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  username: PropTypes.any,
  order: PropTypes.any,
  title: PropTypes.string,
  submitBtnTitle: PropTypes.string,
  isOpenApprove: PropTypes.bool,
  showModalApprove: PropTypes.func,
  onApproveOrder: PropTypes.func,
  onRejectOrder: PropTypes.func,
}

const mapStateToProps = ({ login }) => ({
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  username: login.username,
})

const mapDispatchToProps = (dispatch) => ({
  onApproveOrder: (org, tenentId, orderKey) =>
    dispatch(approveOrder(org, tenentId, orderKey)),
  onRejectOrder: (org, tenentId, orderKey) =>
    dispatch(rejectNewOrder(org, tenentId, orderKey)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ApproveCfrmModal))
