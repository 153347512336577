const formatDate = (dateVal) => {
  var newDate = new Date(dateVal)

  var sMonth = padValue(newDate.getMonth() + 1)
  var sDay = padValue(newDate.getDate())
  var sYear = newDate.getFullYear()
  var sHour = newDate.getHours()
  var sMinute = padValue(newDate.getMinutes())
  var sAMPM = 'AM'

  var iHourCheck = parseInt(sHour)

  if (iHourCheck > 12) {
    sAMPM = 'PM'
    sHour = iHourCheck - 12
  } else if (iHourCheck === 0) {
    sHour = '12'
  }

  sHour = padValue(sHour)

  return (
    sMonth +
    '/' +
    sDay +
    '/' +
    sYear +
    ' ' +
    sHour +
    ':' +
    sMinute +
    ' ' +
    sAMPM
  )
}

const formatDateV2 = (dateVal) => {
  var newDate = new Date(dateVal)

  var sMonth = padValue(newDate.getMonth() + 1)
  var sMonthString = ''
  var sDay = padValue(newDate.getDate())
  var sHour = newDate.getHours()
  var sMinute = padValue(newDate.getMinutes())
  var sAMPM = 'AM'

  var iHourCheck = parseInt(sHour)

  switch (sMonth) {
    case 1:
      sMonthString = 'Jan'
      break
    case 2:
      sMonthString = 'Feb'
      break
    case 3:
      sMonthString = 'Mar'
      break
    case 4:
      sMonthString = 'Apr'
      break
    case 5:
      sMonthString = 'May'
      break
    case 6:
      sMonthString = 'Jun'
      break
    case 7:
      sMonthString = 'Jul'
      break
    case 8:
      sMonthString = 'Aug'
      break
    case 9:
      sMonthString = 'Sept'
      break
    case 10:
      sMonthString = 'Oct'
      break
    case 11:
      sMonthString = 'Nov'
      break
    case 12:
      sMonthString = 'Dec'
      break
  }

  if (iHourCheck > 12) {
    sAMPM = 'PM'
    sHour = iHourCheck - 12
  } else if (iHourCheck === 0) {
    sHour = '12'
  }

  sHour = padValue(sHour)

  return sMonthString + ' ' + sDay + ', ' + sHour + ':' + sMinute + ' ' + sAMPM
}

const formatDateOnly = (dateVal) => {
  var newDate = new Date(dateVal)

  var sMonth = padValue(newDate.getMonth() + 1)
  var sDay = padValue(newDate.getDate())
  var sYear = newDate.getFullYear()

  return sMonth + '/' + sDay + '/' + sYear + ' '
}

const formatTimeOnly = (dateVal) => {
  var sHour = padValue(dateVal.getHours())
  var sMinute = padValue(dateVal.getMinutes())
  var sAMPM = 'AM'

  if (dateVal.getHours() > 12) {
    sAMPM = 'PM'
    sHour = sHour - 12
  } else if (dateVal.getHours() === 12) {
    sAMPM = 'PM'
    sHour = '12'
  } else if (dateVal.getHours() === 0) {
    sHour = '12'
  }

  return sHour + ':' + sMinute + ' ' + sAMPM
}

const padValue = (value) => {
  return value < 10 ? '0' + value : value
}

const isToday = (someDate) => {
  const today = new Date()
  if (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  ) {
    return true
  } else {
    return false
  }
}

const isTomrrow = (due_date) => {
  var tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  if (
    tomorrow.getDate() == due_date.getDate() &&
    tomorrow.getMonth() == due_date.getMonth() &&
    tomorrow.getFullYear() == due_date.getFullYear()
  ) {
    return true
  } else {
    return false
  }
}

const isLate = (due_date) => {
  if (new Date() > due_date) {
    return true
  } else {
    return false
  }
}

export {
  formatDate,
  formatDateV2,
  isToday,
  formatDateOnly,
  formatTimeOnly,
  isTomrrow,
  isLate,
}
