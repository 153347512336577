/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import GlistenDropdown from '../../../components/GlistenDropdown'

import { Row, Col, Modal, Button, Label, Media, Table } from 'reactstrap'

import { assignWork, getUsersData, rejectNewOrder } from 'store/actions'

import './assign.scss'
import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'
import { sendPageViewEvent } from '../../../firebase'
import { useForm, FormProvider } from 'react-hook-form'

const AssignCfrmModal = (props) => {
  const { onGetUsersData, onAssignOrder } = props
  const methods = useForm()

  const {
    handleSubmit,
    formState: { errors },
  } = methods

  useEffect(() => {
    sendPageViewEvent('Assign Detailers Modal')
  }, [])

  useEffect(() => {
    onGetUsersData(props.org, props.tenant)
  }, [onGetUsersData])

  const detailerNames = (employ_array) => {
    var detailers = employ_array.map((item) => ({
      key: item.data_key,
      value: item.first_name + ' ' + item.last_name,
    }))
    console.log(detailers)
    return detailers
  }

  const onSubmit = (evt) => {
    onAssignOrder(
      props.org,
      props.tenant,
      props.order.data_key,
      evt.detailer.toLowerCase()
    )
    props.showModalAssign(false)
  }

  return (
    <FormProvider {...methods}>
      <Modal
        scrollable={true}
        size='xl'
        isOpen={props.isOpenAssign}
        toggle={() => {
          props.showModalAssign(false)
        }}
        centered={true}
      >
        {/* HEADER */}
        <div className='modal-header glisten-modal-header'>
          <div>
            <h3 className='glisten-modal-title'>{props.title}</h3>
          </div>

          <button
            className='close-btn'
            onClick={() => {
              props.showModalAssign(false)
            }}
          >
            <Logo />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='form-horizontal'>
          {/* BODY */}
          <div
            className='modal-body glisten-modal-body'
            style={{ paddingTop: '1.188rem' }}
          >
            <Row className='row-margin-fix'>
              <Col xl='12'>
                {props.order && props.order.service_catalog ? (
                  <Label
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '200',
                      fontSize: '1rem',
                      letterSpacing: '0.133rem',
                      lineHeight: '1.438rem',
                      color: '#000000',
                      marginTop: '3.438rem',
                      marginBottom: '0rem',
                      paddingLeft: '0.5rem',
                    }}
                  >
                    {props.order.service_catalog.map((val, idx) =>
                      idx == props.order.service_catalog.length - 1
                        ? val.name
                        : val.name + ', '
                    )}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row className='row-margin-fix'>
              <Col
                xl='6'
                style={{ marginBottom: '6.25rem', marginTop: '1.875rem' }}
              >
                {/* Assign Detailer Dropdown */}

                <GlistenDropdown
                  formRegistragionName='detailer'
                  placeholder={props.t('Detailer')}
                  items={detailerNames(
                    props.data.filter(
                      (employee) => employee.role.toUpperCase() == 'EMPLOYEE'
                    )
                  )}
                  value=''
                  validationMsg={props.t('Select a detailer')}
                />
              </Col>
            </Row>
          </div>

          {/* FOOTER */}
          <div className='modal-footer glisten-modal-footer'>
            <div>
              <Row className='row-margin-fix'>
                <Col
                  lg='6'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0rem',
                  }}
                >
                  {props.userFeat.includes('ftl-detailshop') &&
                    (props.userFeat.includes('ftl-manager') ||
                      props.userFeat.includes('ftl-executive') ||
                      props.userFeat.includes('ftl-admin')) &&
                    props.order &&
                    (props.order.status == 'Approved' ||
                      props.order.status == 'New') && (
                      <Button
                        name='reject'
                        className='sec-modal-btn btn glisten-btn'
                        style={{ marginBottom: '1.125rem' }}
                        onClick={() => {
                          props.onRejectOrder(
                            props.org,
                            props.tenant,
                            props.order.data_key
                          )
                          props.showModalAssign(false)
                        }}
                      >
                        {props.t('REJECT')}
                      </Button>
                    )}
                </Col>
                <Col
                  lg='6'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0rem',
                  }}
                >
                  {props.userFeat.includes('ftl-detailshop') &&
                    (props.userFeat.includes('ftl-manager') ||
                      props.userFeat.includes('ftl-executive') ||
                      props.userFeat.includes('ftl-admin')) &&
                    props.order &&
                    (props.order.status == 'Approved' ||
                      props.order.status == 'New') && (
                      <Button
                        className='prim-modal-btn btn glisten-btn'
                        type='submit'
                        style={{ marginBottom: '1.125rem' }}
                      >
                        {props.t('ASSIGN')}
                      </Button>
                    )}
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </Modal>
    </FormProvider>
  )
}

AssignCfrmModal.propTypes = {
  t: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  username: PropTypes.any,
  order: PropTypes.any,
  title: PropTypes.string,
  submitBtnTitle: PropTypes.string,
  isOpenAssign: PropTypes.bool,
  showModalAssign: PropTypes.func,
  onAssignOrder: PropTypes.func,
  onRejectOrder: PropTypes.func,
  onGetUsersData: PropTypes.func,
  data: PropTypes.any,
}

const mapStateToProps = ({ users, login }) => ({
  data: users.data,
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  username: login.username,
})

const mapDispatchToProps = (dispatch) => ({
  onAssignOrder: (org, tenentId, orderKey, targetEmployeeKey) =>
    dispatch(assignWork(org, tenentId, orderKey, targetEmployeeKey)),
  onRejectOrder: (org, tenentId, orderKey) =>
    dispatch(rejectNewOrder(org, tenentId, orderKey)),
  onGetUsersData: (org, tenant) => dispatch(getUsersData(org, tenant)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AssignCfrmModal))
