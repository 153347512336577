/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Row, Col, Input } from 'reactstrap'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import DayPicker from 'react-day-picker'
import '../../../assets/scss/custom/style.css'
import '../ServicePriority/selectservice.scss'

import { Controller, useFormContext } from 'react-hook-form'

import GlistenSelectableButton from '../../../components/GlistenSelectableButton'
import GlistenDropdown from '../../../components/GlistenDropdown'
import { SERVICE_PRIORITY, OPERATION_TIMES } from 'common/contants'
import { sendPageViewEvent } from '../../../firebase'

const ServicePriority = (props) => {
  const methods = useFormContext()
  const { control } = methods

  useEffect(() => {
    sendPageViewEvent('Select Service Priority')
  }, [])

  return (
    <>
      <Row style={{ marginTop: '2.5rem' }}>
        {SERVICE_PRIORITY.map((item) => (
          <Col
            key={item.key}
            lg={2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Controller
              control={control}
              name='priority'
              render={({ field }) => (
                <GlistenSelectableButton
                  img={item.image}
                  title={props.t(item.title)}
                  selected={field.value}
                  value={item.key}
                  setSelected={(e) => {
                    field.onChange(e.value)
                  }}
                />
              )}
            />
          </Col>
        ))}
      </Row>
      <Row style={{ marginTop: '4.688rem' }}>
        <Col lg={12}>
          <h4 className='modal-title'>
            {props.t('Select a pick-up date and time')}
          </h4>
        </Col>
      </Row>
      <Row style={{ marginTop: '2.25rem' }}>
        <Col lg={6} style={{ padding: '0' }}>
          <Controller
            control={control}
            name='date'
            render={({ field }) => (
              <DayPicker
                disabledDays={[{ before: new Date() }]}
                fromMonth={new Date()}
                onDayClick={(e) => field.onChange(e)}
                selectedDays={field.value}
              />
            )}
          />
        </Col>
        {/* <Col lg={4}>
          <div style={{ marginTop: '1rem' }}>
            <h6 className={'subsection-title'}>{props.t('Time')}</h6>
            <Controller
              control={control}
              name='time'
              render={({ field }) => (
                <Input
                  className='form-control'
                  style={{
                    border: 0,
                    outline: 0,
                    background: 'transparent',
                    borderBottom: '1px solid black',
                  }}
                  type='time'
                  value={field.value}
                  onChange={(e) => field.onChange(e)}
                  id='example-time-input'
                />
              )}
            />
          </div>
        </Col> */}

        <Col lg={4}>
          <div style={{ marginTop: '1rem' }}>
            <h6 className={'subsection-title'}>{props.t('Time')}</h6>
            <GlistenDropdown
              formRegistragionName='time'
              placeholder={props.t('Select pick up time')}
              items={OPERATION_TIMES}
              value=''
              validationMsg={props.t('Select a time')}
            />
          </div>
        </Col>

        {/* <Col lg={4}>
          <div style={{ marginTop: '1rem' }}>
          <GlistenDropdown
                formRegistragionName='time-dropdown'
                placeholder={props.t('Select pick up time')}
                items={OPERATION_TIMES}
                value=''
                validationMsg={props.t('Select a time')}
              />
          
          </div>
        </Col> */}
      </Row>
    </>
  )
}

ServicePriority.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ServicePriority))
