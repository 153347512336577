import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import ModalWithDynamicContent from '../../components/ModalWithDynamicContent'
import EnterVehicleInformation from './EnterVehicleInformation'
import SelectService from './SelectService'
import ServicePriority from './ServicePriority'
import Confirmation from './Confirmation'
import LoadingOverlay from 'react-loading-overlay'
import { submitServiceOrder, clearVehicleSearchResult } from 'store/actions'
import { ERROR_CODE_MSG_MAPPING } from '../../common/contants'

const AddNewService = (props) => {
  const [currContentIdx, setCurrContentIdx] = useState(0)
  const [title, setTitle] = useState(props.t('Enter Vehicle Information'))
  const [showBack, setShowBack] = useState(false)
  const [submitBtnTitle, setSubmitBtnTitle] = useState(props.t('Next'))
  const [orderInfo, setOrderinfo] = useState({})
  const [lastActionTriggered, setLastActionTriggered] = useState(false)

  const changeContent = (idx, data) => {
    if (idx < 4) {
      setCurrContentIdx(idx)
    }
    switch (idx) {
      case 0:
        setTitle(props.t('Enter Vehicle Information'))
        setShowBack(false)
        setSubmitBtnTitle(props.t('Next'))
        break
      case 1:
        setTitle(props.t('What type of service?'))
        setShowBack(true)
        setSubmitBtnTitle(props.t('Next'))
        break
      case 2:
        setTitle(props.t('Service Priority'))
        setShowBack(true)
        setSubmitBtnTitle(props.t('Next'))
        break
      case 3:
        setOrderinfo(data)
        setTitle(props.t('Confirmation'))
        setShowBack(true)
        setSubmitBtnTitle(props.t('Submit'))
        break
      case 4:
        var dateTime = data.date
        dateTime.setHours(
          parseInt(data.time.split(':')[0]),
          parseInt(data.time.split(':')[1]),
          0
        )

        var tmp = data.services.filter(
          (item) =>
            data.custom && data.customs.map((a) => a.data_key).indexOf(item) < 0
        )

        var orderData = {
          priority: data.priority,
          pickupDateTime: dateTime.getTime(),
          comment: data.note,
          servicecatalogId: tmp.length > 0 ? tmp : data.services,
          stock: data.stock,
          customerTag: data.tag,
          poNumber: data.poNum,
          note: data.note,
          vehicle: {
            vin: data.vin,
            make: data.make,
            model: data.model,
            year: data.year,
            color: data.color,
          },
          customServices: data.customs
            ? data.customs
                .filter((item) => data.services.indexOf(item.data_key) !== -1)
                .map((item) => {
                  return {
                    name: item.name,
                    description: item.description,
                    retailCost: item.retail_cost,
                    tenantId: props.tenant,
                    targetTenantId: props.targetTenant.data_key,
                  }
                })
            : null,
        }

        props.onSubmitServiceOrder(props.org, props.tenant, orderData)
        setLastActionTriggered(true)

        break
    }
  }

  if (lastActionTriggered == true) {
    if (props.isLoading == false && props.error == null && currContentIdx < 4) {
      props.setmodal_center(false)
      setCurrContentIdx(0)
      setTitle(props.t('Enter Vehicle Information'))
      setShowBack(false)
      setSubmitBtnTitle(props.t('Next'))
      props.onClearVehicleSearchResult()
      setLastActionTriggered(false)
    } else if (props.isLoading == false && props.error != null) {
      alert(ERROR_CODE_MSG_MAPPING[props.error.code])
      setLastActionTriggered(false)
    }
  }

  return (
    <ModalWithDynamicContent
      title={title}
      content={(() => {
        switch (currContentIdx) {
          case 0:
            return (
              <LoadingOverlay active={props.loading} spinner>
                <EnterVehicleInformation />
              </LoadingOverlay>
            )
          case 1:
            return <SelectService />
          case 2:
            return <ServicePriority />
          case 3:
            return <Confirmation orderData={orderInfo} />
        }
      })()}
      isOpen={props.isOpen}
      tog_center={props.tog_center}
      showModal={props.setmodal_center}
      currentContent={currContentIdx}
      showBack={showBack}
      submitBtnTitle={submitBtnTitle}
      submitBtnDisabled={props.loading}
      onSubmit={(currContent, data) => {
        changeContent(currContent, data)
      }}
      onClose={() => props.onClearVehicleSearchResult()}
    />
  )
}

AddNewService.propTypes = {
  t: PropTypes.any,
  setmodal_center: PropTypes.func,
  isOpen: PropTypes.bool,
  tog_center: PropTypes.func,
  loading: PropTypes.any,
  selectedServices: PropTypes.any,
  customServices: PropTypes.any,
  targetTenant: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  username: PropTypes.string,
  userFeat: PropTypes.any,
  error: PropTypes.any,
  isLoading: PropTypes.any,
  onSubmitServiceOrder: PropTypes.func,
  onClearVehicleSearchResult: PropTypes.func,
}

const mapStateToProps = ({ login, service, tenant }) => ({
  selectedServices: service.selectedServices,
  customServices: service.customServices,
  tenant: login.tenant,
  username: login.username,
  org: login.org,
  userFeat: login.features,
  targetTenant: tenant.targetTenant,
  error: service.error,
  isLoading: service.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  onSubmitServiceOrder: (org, tenantId, orderData) =>
    dispatch(submitServiceOrder(org, tenantId, orderData)),
  onClearVehicleSearchResult: () => dispatch(clearVehicleSearchResult()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddNewService))
