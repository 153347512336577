/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'

import { Row, Col, Label } from 'reactstrap'
import GlistenServiceOrderCard from '../GlistenServiceOrderCard'
import { isMobile } from 'react-device-detect'

import '../GlistenServiceKanban/servicekanban.scss'

const GlistenServiceKanban = (props) => {
  const [laneSelected, setLaneSelected] = useState('0')

  return (
    <>
      <Row className='d-sm-none .d-md-block'>
        <Col xs='12' style={{ display: 'flex', flexDirection: 'column' }}>
          <select
            className='lane-select'
            style={{
              outline: 0,
              border: 0,
            }}
            value={laneSelected}
            onChange={(e) => setLaneSelected(e.target.value)}
          >
            <option value='0'>
              {`${props.t('New')} 
                (${
                  props.newOrders.filter((item) => item.isFilterOut == false)
                    .length
                })`}
            </option>
            <option value='1'>
              {`${props.t('In Progress')} (${
                props.inProgressOrders.filter(
                  (item) => item.isFilterOut == false
                ).length
              })`}
            </option>
            <option value='2'>{`${props.t('In Review')} (${
              props.reviewOrders.filter((item) => item.isFilterOut == false)
                .length
            })`}</option>
            <option value='3'>{`${props.t('Completed Work')} (${
              props.completedOrders.length
            })`}</option>
          </select>
        </Col>
      </Row>

      {/* L A Y O U T for mobile displays*/}
      <Row style={!isMobile ? { paddingTop: '0rem' } : { paddingTop: '1rem' }}>
        <Col sm='4' className='new-lane'>
          {/* NEW ORDERS LANE*/}
          {laneSelected == '0' ? (
            <Row className='d-sm-none .d-md-block'>
              <Col
                sm='12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                {/* Detailshop users should not see 'New' status orders that have been created but not approved by dealership. */}
                {props.newOrders &&
                  props.newOrders
                    .filter((item) => item.isFilterOut == false)
                    .map(
                      (item) =>
                        (item.status == 'WorkAssigned' ||
                          item.status == 'Approved' ||
                          item.status == 'New') &&
                        props.userFeat.includes('ftl-detailshop') && (
                          <GlistenServiceOrderCard
                            key={item.data_key}
                            order={item}
                            loggedInUserRole={props.loggedInUserRole}
                            loggedInUserType={props.loggedInUserType}
                            isOpenApprove={props.isOpenApprove}
                            showModalApprove={props.showModalApprove}
                            isOpenStartEndReview={props.isOpenStartEndReview}
                            showModalStartEndReview={
                              props.showModalStartEndReview
                            }
                            isOpenAssign={props.isOpenAssign}
                            showModalAssign={props.showModalAssign}
                            setSelectedOrder={props.setSelectedOrder}
                            setSelectedOrderTitle={props.setSelectedOrderTitle}
                            setSelectedOrderBtn={props.setSelectedOrderBtn}
                          />
                        )
                    )}
                {/* For all Dealership users */}
                {props.newOrders &&
                  props.newOrders
                    .filter((item) => item.isFilterOut == false)
                    .map(
                      (item) =>
                        props.userFeat.includes('ftl-dealership') && (
                          <GlistenServiceOrderCard
                            key={item.data_key}
                            order={item}
                            loggedInUserRole={props.loggedInUserRole}
                            loggedInUserType={props.loggedInUserType}
                            isOpenApprove={props.isOpenApprove}
                            showModalApprove={props.showModalApprove}
                            isOpenStartEndReview={props.isOpenStartEndReview}
                            showModalStartEndReview={
                              props.showModalStartEndReview
                            }
                            isOpenAssign={props.isOpenAssign}
                            showModalAssign={props.showModalAssign}
                            setSelectedOrder={props.setSelectedOrder}
                            setSelectedOrderTitle={props.setSelectedOrderTitle}
                            setSelectedOrderBtn={props.setSelectedOrderBtn}
                          />
                        )
                    )}
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>

        {/* IN PROGRESS ORDERS LANE */}
        <Col sm='4'>
          {laneSelected == '1' ? (
            <Row className='d-sm-none .d-md-block'>
              <Col
                sm='12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                {props.inProgressOrders &&
                  props.inProgressOrders
                    .filter((item) => item.isFilterOut == false)
                    .map((item) => (
                      <GlistenServiceOrderCard
                        key={item.data_key}
                        order={item}
                        loggedInUserRole={props.loggedInUserRole}
                        loggedInUserType={props.loggedInUserType}
                        isOpenApprove={props.isOpenApprove}
                        showModalApprove={props.showModalApprove}
                        isOpenStartEndReview={props.isOpenStartEndReview}
                        showModalStartEndReview={props.showModalStartEndReview}
                        isOpenAssign={props.isOpenAssign}
                        showModalAssign={props.showModalAssign}
                        setSelectedOrder={props.setSelectedOrder}
                        setSelectedOrderTitle={props.setSelectedOrderTitle}
                        setSelectedOrderBtn={props.setSelectedOrderBtn}
                      />
                    ))}
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>

        {/* FOR REVIEW ORDERS LANE*/}
        <Col sm='4' className='review-lane'>
          {laneSelected == '2' ? (
            <Row className='d-sm-none .d-md-block'>
              <Col
                sm='12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                {props.reviewOrders &&
                  props.reviewOrders
                    .filter((item) => item.isFilterOut == false)
                    .map((item) => (
                      <GlistenServiceOrderCard
                        key={item.data_key}
                        order={item}
                        loggedInUserRole={props.loggedInUserRole}
                        loggedInUserType={props.loggedInUserType}
                        isOpenApprove={props.isOpenApprove}
                        showModalApprove={props.showModalApprove}
                        isOpenStartEndReview={props.isOpenStartEndReview}
                        showModalStartEndReview={props.showModalStartEndReview}
                        isOpenAssign={props.isOpenAssign}
                        showModalAssign={props.showModalAssign}
                        setSelectedOrder={props.setSelectedOrder}
                        setSelectedOrderTitle={props.setSelectedOrderTitle}
                        setSelectedOrderBtn={props.setSelectedOrderBtn}
                      />
                    ))}
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>

        {/* FOR COMPLETED ORDERS LANE*/}
        <Col sm='4' className='review-lane'>
          {laneSelected == '3' ? (
            <Row className='d-sm-none .d-md-block'>
              <Col
                sm='12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                {props.completedOrders &&
                  props.completedOrders
                    .filter((item) => item.isFilterOut == false)
                    .map((item) => (
                      <GlistenServiceOrderCard
                        key={item.data_key}
                        order={item}
                        loggedInUserRole={props.loggedInUserRole}
                        loggedInUserType={props.loggedInUserType}
                        isOpenApprove={props.isOpenApprove}
                        showModalApprove={props.showModalApprove}
                        isOpenStartEndReview={props.isOpenStartEndReview}
                        showModalStartEndReview={props.showModalStartEndReview}
                        isOpenAssign={props.isOpenAssign}
                        showModalAssign={props.showModalAssign}
                        setSelectedOrder={props.setSelectedOrder}
                        setSelectedOrderTitle={props.setSelectedOrderTitle}
                        setSelectedOrderBtn={props.setSelectedOrderBtn}
                      />
                    ))}
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      </Row>

      {/* L A Y O U T for larger than mobile displays*/}
      <Row style={!isMobile ? { paddingTop: '0rem' } : { paddingTop: '1rem' }}>
        <Col sm='4' className='new-lane'>
          {/* NEW ORDERS LANE*/}
          <Row className='d-none d-sm-block'>
            <Col sm='12'>
              {/* Displays correct column card count based on what is being displayed */}
              {props.userFeat.includes('ftl-detailshop') ? (
                <h5 className='lane-title'>
                  {`${props.t('New')} 
                (${
                  props.newOrders.filter((item) => item.isFilterOut == false)
                    .length
                })`}
                </h5>
              ) : (
                <h5 className='lane-title'>
                  {`${props.t('New')} 
                (${
                  props.newOrders.filter((item) => item.isFilterOut == false)
                    .length
                })`}
                </h5>
              )}
            </Col>
            <Col
              sm='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              {/* Detailshop users should not see 'New' status orders that have been created but not approved by dealership. */}
              {props.newOrders &&
                props.newOrders
                  .filter((item) => item.isFilterOut == false)
                  .map(
                    (item) =>
                      (item.status == 'WorkAssigned' ||
                        item.status == 'Approved' ||
                        item.status == 'New') &&
                      props.userFeat.includes('ftl-detailshop') && (
                        <GlistenServiceOrderCard
                          key={item.data_key}
                          order={item}
                          loggedInUserRole={props.loggedInUserRole}
                          loggedInUserType={props.loggedInUserType}
                          isOpenApprove={props.isOpenApprove}
                          showModalApprove={props.showModalApprove}
                          isOpenStartEndReview={props.isOpenStartEndReview}
                          showModalStartEndReview={
                            props.showModalStartEndReview
                          }
                          isOpenAssign={props.isOpenAssign}
                          showModalAssign={props.showModalAssign}
                          setSelectedOrder={props.setSelectedOrder}
                          setSelectedOrderTitle={props.setSelectedOrderTitle}
                          setSelectedOrderBtn={props.setSelectedOrderBtn}
                        />
                      )
                  )}
              {/* For all Dealership users */}
              {props.newOrders &&
                props.newOrders
                  .filter((item) => item.isFilterOut == false)
                  .map(
                    (item) =>
                      props.userFeat.includes('ftl-dealership') && (
                        <GlistenServiceOrderCard
                          key={item.data_key}
                          order={item}
                          loggedInUserRole={props.loggedInUserRole}
                          loggedInUserType={props.loggedInUserType}
                          isOpenApprove={props.isOpenApprove}
                          showModalApprove={props.showModalApprove}
                          isOpenStartEndReview={props.isOpenStartEndReview}
                          showModalStartEndReview={
                            props.showModalStartEndReview
                          }
                          isOpenAssign={props.isOpenAssign}
                          showModalAssign={props.showModalAssign}
                          setSelectedOrder={props.setSelectedOrder}
                          setSelectedOrderTitle={props.setSelectedOrderTitle}
                          setSelectedOrderBtn={props.setSelectedOrderBtn}
                        />
                      )
                  )}
            </Col>
          </Row>
        </Col>

        {/* IN PROGRESS ORDERS LANE */}
        <Col sm='4'>
          <Row className='d-none d-sm-block'>
            <Col sm='12' className='progress-lane'>
              <h5 className='lane-title'>{`${props.t('In Progress')} (${
                props.inProgressOrders.filter(
                  (item) => item.isFilterOut == false
                ).length
              })`}</h5>
            </Col>
            <Col
              sm='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              {props.inProgressOrders &&
                props.inProgressOrders
                  .filter((item) => item.isFilterOut == false)
                  .map((item) => (
                    <GlistenServiceOrderCard
                      key={item.data_key}
                      order={item}
                      loggedInUserRole={props.loggedInUserRole}
                      loggedInUserType={props.loggedInUserType}
                      isOpenApprove={props.isOpenApprove}
                      showModalApprove={props.showModalApprove}
                      isOpenStartEndReview={props.isOpenStartEndReview}
                      showModalStartEndReview={props.showModalStartEndReview}
                      isOpenAssign={props.isOpenAssign}
                      showModalAssign={props.showModalAssign}
                      setSelectedOrder={props.setSelectedOrder}
                      setSelectedOrderTitle={props.setSelectedOrderTitle}
                      setSelectedOrderBtn={props.setSelectedOrderBtn}
                    />
                  ))}
            </Col>
          </Row>
        </Col>

        {/* FOR REVIEW ORDERS LANE*/}
        <Col sm='4' className='review-lane'>
          <Row className='d-none d-sm-block'>
            <Col sm='12'>
              {props.userFeat.includes('ftl-dealership') ? (
                <h5 className='lane-title'>{`${props.t('In Review')} (${
                  props.reviewOrders.filter((item) => item.isFilterOut == false)
                    .length
                })`}</h5>
              ) : (
                <h5 className='lane-title'>{`${props.t('For Review')} (${
                  props.reviewOrders.filter((item) => item.isFilterOut == false)
                    .length
                })`}</h5>
              )}
            </Col>
            <Col
              sm='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              {props.reviewOrders &&
                props.reviewOrders
                  .filter((item) => item.isFilterOut == false)
                  .map((item) => (
                    <GlistenServiceOrderCard
                      key={item.data_key}
                      order={item}
                      loggedInUserRole={props.loggedInUserRole}
                      loggedInUserType={props.loggedInUserType}
                      isOpenApprove={props.isOpenApprove}
                      showModalApprove={props.showModalApprove}
                      isOpenStartEndReview={props.isOpenStartEndReview}
                      showModalStartEndReview={props.showModalStartEndReview}
                      isOpenAssign={props.isOpenAssign}
                      showModalAssign={props.showModalAssign}
                      setSelectedOrder={props.setSelectedOrder}
                      setSelectedOrderTitle={props.setSelectedOrderTitle}
                      setSelectedOrderBtn={props.setSelectedOrderBtn}
                    />
                  ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

GlistenServiceKanban.propTypes = {
  t: PropTypes.any,
  newOrders: PropTypes.any,
  inProgressOrders: PropTypes.any,
  reviewOrders: PropTypes.any,
  completedOrders: PropTypes.any,
  userFeat: PropTypes.any,
  loggedInUserRole: PropTypes.string,
  loggedInUserType: PropTypes.string,
  isOpenStartEndReview: PropTypes.bool,
  showModalStartEndReview: PropTypes.func,
  isOpenApprove: PropTypes.bool,
  showModalApprove: PropTypes.func,
  isOpenAssign: PropTypes.bool,
  showModalAssign: PropTypes.func,
  setSelectedOrder: PropTypes.func,
  setSelectedOrderTitle: PropTypes.func,
  setSelectedOrderBtn: PropTypes.func,
}

const mapStateToProps = ({ login }) => ({
  userFeat: login.features,
})

export default connect(mapStateToProps)(
  withRouter(withTranslation()(GlistenServiceKanban))
)

// withTranslation()(GlistenServiceKanban)
