/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Button, Form, Input, Media } from 'reactstrap'
import Drawer from 'react-bottom-drawer'
import { isMobile } from 'react-device-detect'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import '../Whiteboard/whiteboard.scss'
import { withTranslation } from 'react-i18next'
import AddNewService from 'pages/AddNewService'

import GlistenServiceKanban from '../../components/GlistenServiceKanban'
import GlistenHorizontalKanban from '../../components/GlistenHorizontalKanban'

import { connect } from 'react-redux'

import { getAllOrders, filterOrderDataByIdNum } from 'store/actions'

import { ReactComponent as SearchIcon } from '../../assets/images/ic-search.svg'
import glisten_logo from '../../assets/images/glisten/glisten-logo-wht.svg'

import LoadingOverlay from 'react-loading-overlay'
import ApproveCfrmModal from './ApproveCfrmModal'
import AssignCfrmModal from './AssignCnfrmModal'
import StartEndReviewCfrmModal from './StartEndReviewCfrmModal'

import { sendPageViewEvent } from '../../firebase'
import { useForm } from 'react-hook-form'

const Whiteboard = (props) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useForm()

  useEffect(() => {
    sendPageViewEvent('Whiteboard')
  }, [])

  const { onGetAllOrders, onFilterOrderDataByIdNum } = props

  useEffect(() => {
    onGetAllOrders(props.org, props.tenant)
  }, [onGetAllOrders])

  var title = `${
    isMobile ? '' : props.loggedInUserFirstName + "'s "
  } Whiteboard`
  props.setPageTitle(title)
  const [isVisible, setIsVisible] = React.useState(false)
  const openDrawer = React.useCallback(() => setIsVisible(true), [])
  const closeDrawer = React.useCallback(() => setIsVisible(false), [])
  const [modal_center, setmodal_center] = useState(false)

  const [selectedOrder, setSelectedOrder] = useState()
  const [selectedOrderTitle, setSelectedOrderTitle] = useState('')
  const [selectedOrderBtn, setSelectedOrderBtn] = useState('')
  const [modal_approve, setmodal_approve] = useState(false)
  const [modal_assign, setmodal_assign] = useState(false)
  const [modal_startendreview, setmodal_startendreview] = useState(false)

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  const filterByNum = (va) => {
    onFilterOrderDataByIdNum(va.target.value)
  }

  const WhiteBoardButtonComponent = () => (
    <div
      className='align-self-center'
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      {/* search bar */}
      <form className='glsn-box' onChange={filterByNum}>
        <div
          style={
            !isMobile
              ? { marginTop: '0.8em', marginLeft: '1.5em' }
              : { marginTop: 'calc(5%)', marginLeft: 'calc(5%)' }
          }
        >
          <SearchIcon />
        </div>
        <input
          id='search'
          autoFocus={
            (watch('search') != null && watch('search').length) > 0
              ? true
              : false
          }
          name='search'
          label={props.t('SEARCH')}
          placeholder={props.t('SEARCH')}
          className='srch-inpt'
          {...register('search', {
            maxLength: 20,
          })}
        />
      </form>

      {props.userFeat.includes('ftl-dealership') ||
      (props.userFeat.includes('ftl-detailshop') &&
        props.userFeat.includes('ftl-executive')) ? (
        <>
          <div
            className='align-self-center'
            style={
              !isMobile
                ? {
                    height: '2.25rem',
                    width: '0.063rem',
                    background: '#D0D0D0',
                    marginLeft: '1.563rem',
                    marginRight: '1.563rem',
                  }
                : {
                    height: '2.25rem',
                    width: '0.063rem',
                    background: '#D0D0D0',
                    marginLeft: '0.5rem',
                    marginRight: '0.5rem',
                  }
            }
          ></div>

          <Button
            data-toggle='modal'
            data-target='.bs-example-modal-center'
            color='dark'
            className='prim-btn-big'
            style={{ float: 'right' }}
            onClick={() => {
              tog_center()
            }}
          >
            {props.t('New Order')}
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  )

  return (
    <React.Fragment>
      {/* {isMobile ? ( */}
      <div className='breadsrumb-content'>
        <Breadcrumbs
          title={props.t('Home')}
          breadcrumbItem={title}
          showBack={false}
          filter={<WhiteBoardButtonComponent />}
        />
      </div>
      {/* ) : (
        // <div style={{ paddingTop: '4rem' }} />
        <></>
      )} */}

      <div className='wb-content'>
        <MetaTags>
          <title>Home | {title}</title>
        </MetaTags>
        <LoadingOverlay active={props.loading} spinner>
          <Container fluid style={{ maxWidth: '100%', padding: '0rem' }}>
            <Row style={{ paddingBottom: '4rem' }}>
              <Col sm='12'>
                <GlistenServiceKanban
                  newOrders={props.newOrders}
                  inProgressOrders={props.inProgressOrders}
                  reviewOrders={props.inReviewOrders}
                  completedOrders={props.completedOrders}
                  loggedInUserRole={props.loggedInUserRole}
                  loggedInUserType={props.loggedInUserType}
                  isOpenApprove={modal_approve}
                  showModalApprove={setmodal_approve}
                  isOpenStartEndReview={modal_startendreview}
                  showModalStartEndReview={setmodal_startendreview}
                  isOpenAssign={modal_assign}
                  showModalAssign={setmodal_assign}
                  setSelectedOrder={setSelectedOrder}
                  setSelectedOrderTitle={setSelectedOrderTitle}
                  setSelectedOrderBtn={setSelectedOrderBtn}
                />
              </Col>
            </Row>
          </Container>
        </LoadingOverlay>
      </div>

      <Row
        className='d-none d-sm-flex row-margin-fix'
        hidden={isVisible}
        style={
          !isMobile
            ? {
                backgroundColor: 'black',
                borderTop: '1px solid #E7E7E7',
                textAlign: 'left',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                marginBottom: '0rem',
                height: '3.75rem',
                position: 'fixed',
                left: '0',
                bottom: '0',
                width: '100%',
              }
            : {
                backgroundColor: 'black',
                borderTop: '1px solid #E7E7E7',
                textAlign: 'left',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                marginBottom: '0rem',
                height: '6rem',
                position: 'fixed',
                left: '0',
                bottom: '0',
                width: '100%',
              }
        }
      >
        <Col
          sm='6'
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '0rem',
          }}
        >
          <button
            style={{
              background: 'transparent',
              border: '0 solid',
              alignSelf: 'center',
            }}
          >
            <h5
              style={{
                color: 'white',
                fontFamily: 'Poppins',
                fontWeight: '600',
                fontSize: '0.75rem',
                letterSpacing: '0.084rem',
                lineHeight: '1.063rem',
                marginBottom: '0rem',
              }}
              onClick={openDrawer}
            >
              {`${props.t('COMPLETED WORK')} (${
                Object.values(props.completedOrders).length
              })`}{' '}
              <i className='fa fa-fw fa-angle-up' />
            </h5>
          </button>
        </Col>
        <Col
          sm='6'
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: '0rem',
          }}
        >
          <Media
            src={glisten_logo}
            style={{
              display: 'block',
              maxWidth: '100%',
              height: 'auto',
              width: '5.25rem',
            }}
          ></Media>
        </Col>
      </Row>
      {/* <div
        hidden={isVisible}
        style={{
          backgroundColor: 'black',
          borderTop: '1px solid #E7E7E7',
          textAlign: 'left',
          paddingLeft: '1.5rem',
          marginBottom: '0rem',
          height: '4.5rem',
          position: 'fixed',
          left: '0',
          bottom: '0',
          width: '100%',
        }}
      >
      </div> */}

      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
        <div style={{ background: 'black' }}>
          <button style={{ background: 'transparent', border: '0 solid' }}>
            <h5
              style={{ color: 'white', paddingLeft: '1rem' }}
              onClick={closeDrawer}
            >
              {`${props.t('COMPLETED WORK')} (${
                Object.values(props.completedOrders).length
              })`}{' '}
              <i className='fa fa-fw fa-angle-down' />
            </h5>
          </button>
          <GlistenHorizontalKanban
            completedOrders={props.completedOrders}
            loggedInUserRole={props.loggedInUserRole}
            loggedInUserType={props.loggedInUserType}
          />
        </div>
      </Drawer>

      <AddNewService
        isOpen={modal_center}
        tog_center={tog_center}
        setmodal_center={setmodal_center}
      />

      {/* Order Action Confirmation Modal for "Approve New Order"*/}
      <ApproveCfrmModal
        title={selectedOrderTitle}
        submitBtnTitle={selectedOrderBtn}
        isOpenApprove={modal_approve}
        showModalApprove={setmodal_approve}
        order={selectedOrder}
      />
      {/* Order Action Confirmation Modal for "Assign Detail to Order"*/}
      <AssignCfrmModal
        title={selectedOrderTitle}
        submitBtnTitle={selectedOrderBtn}
        isOpenAssign={modal_assign}
        showModalAssign={setmodal_assign}
        order={selectedOrder}
      />
      {/* Order Action Confirmation Modal for "Start Job / End Job / Final Review"*/}
      <StartEndReviewCfrmModal
        title={selectedOrderTitle}
        submitBtnTitle={selectedOrderBtn}
        isOpenStartEndReview={modal_startendreview}
        showModalStartEndReview={setmodal_startendreview}
        order={selectedOrder}
      />
    </React.Fragment>
  )
}

Whiteboard.propTypes = {
  loading: PropTypes.any,
  t: PropTypes.any,
  setPageTitle: PropTypes.func,
  loggedInUsername: PropTypes.string,
  loggedInUserRole: PropTypes.string,
  loggedInUserType: PropTypes.string,
  loggedInUserFirstName: PropTypes.string,
  newOrders: PropTypes.any,
  inProgressOrders: PropTypes.any,
  inReviewOrders: PropTypes.any,
  completedOrders: PropTypes.any,
  onGetAllOrders: PropTypes.func,
  onFilterOrderDataByIdNum: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ order, login }) => ({
  loading: order.isLoading,
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  newOrders: order.newOrders,
  inProgressOrders: order.inProgressOrders,
  inReviewOrders: order.inReviewOrders,
  completedOrders: order.completedOrders,
  loggedInUsername: login.username,
  loggedInUserRole: login.role,
  loggedInUserType: login.type,
  loggedInUserFirstName: login.firstName,
})

const mapDispatchToProps = (dispatch) => ({
  onGetAllOrders: (org, tenant) => dispatch(getAllOrders(org, tenant)),
  onFilterOrderDataByIdNum: (id_number) =>
    dispatch(filterOrderDataByIdNum(id_number)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Whiteboard))
