import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

// Styling
import '../GlistenSelectableButton/selectablebtn.scss'

const GlistenSelectableButton = (props) => {
  return (
    <>
      <div>
        <Button
          outline
          color='link'
          className='btn btn-selected'
          value={props.title}
          style={
            props.selected == props.value
              ? {
                  border: '1px solid #0073FF',
                  padding: 'unset',
                  boxShadow: '0 2px 7px 0 rgba(0,0,0,0.18)',
                }
              : {
                  border: '0px solid black',
                  padding: 'unset',
                  boxShadow: '0 2px 7px 0 rgba(0,0,0,0.18)',
                }
          }
          onClick={(e) => {
            e.value = props.value
            props.setSelected(e)
          }}
        >
          <img
            className='img-thumbnail'
            height='40'
            src={props.img}
            style={{
              background: 'white',
              border: 'none',
              paddingTop: '1.75rem',
              paddingRight: '2.063rem',
              paddingBottom: '1.75rem',
              paddingLeft: '2.063rem',
            }}
          />
        </Button>
        <div
          style={{
            fontFamily: 'Poppins',
            fontSize: '0.688rem',
            fontWeight: 'bold',
            lineHeight: '1.063rem',
            letterSpacing: '0.078rem',
            color: '#333333',
            marginTop: '1.625rem',
            // marginLeft: '-3rem',
            textAlign: 'center',
          }}
        >
          {props.title}
        </div>
      </div>
    </>
  )
}

GlistenSelectableButton.propTypes = {
  img: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.any,
  selected: PropTypes.any,
  setSelected: PropTypes.any,
}

export default GlistenSelectableButton
