import {
  GET_ALL_VEHICLE_MAKE,
  GET_ALL_VEHICLE_MAKE_SUCCESS,
  GET_ALL_VEHICLE_MAKE_FAIL,
  GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR,
  GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_SUCCESS,
  GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_FAIL,
  SEARCH_VEHICLE_BY_VIN,
  SEARCH_VEHICLE_BY_VIN_SUCCESS,
  SEARCH_VEHICLE_BY_VIN_FAIL,
  CLEAR_VEHICLE_SEARCH_RESULT,
} from './actionTypes'

import {
  VEHICLE_WHITELIST,
  VEHICLE_ALL_YEAR,
  VEHICLE_ALL_COLOR,
} from '../../../common/contants'

const initialState = {
  allYears: VEHICLE_ALL_YEAR,
  allColors: VEHICLE_ALL_COLOR,
  allMakes: [],
  allModels: [],
  stockNumber: '',
  year: '',
  color: '',
  make: '',
  model: '',
  vin: '',
  history: [],
  error: {},
  loading: false,
}

const vehicle = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_VEHICLE_SEARCH_RESULT:
      console.log(
        'reducer::CLEAR_VEHICLE_SEARCH_RESULT action.payload=' + action.payload
      )

      state = {
        ...state,
        year: '',
        color: '',
        make: '',
        model: '',
        vin: '',
        history: [],
      }
      break

    case GET_ALL_VEHICLE_MAKE:
      console.log(
        'reducer::GET_ALL_VEHICLE_MAKE action.payload=' + action.payload
      )

      state = {
        ...state,
        loading: true,
      }
      break

    case GET_ALL_VEHICLE_MAKE_SUCCESS:
      console.log(
        'reducer::GET_ALL_VEHICLE_MAKE_SUCCESS action.payload=' + action.payload
      )

      var allMakesFromResp = action.payload.Results.map((item) => {
        return { key: item.MakeName, value: item.MakeName }
      })
        .filter((item) => VEHICLE_WHITELIST.includes(item.key))
        .sort((a, b) => (a.key >= b.key ? 1 : -1))

      state = {
        ...state,
        allMakes: allMakesFromResp,
        loading: false,
      }
      break

    case GET_ALL_VEHICLE_MAKE_FAIL:
      console.log(
        'reducer::GET_ALL_VEHICLE_MAKE_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR:
      console.log(
        'reducer::GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR action.payload=' +
          action.payload
      )

      state = {
        ...state,
        loading: true,
      }
      break

    case GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_SUCCESS:
      console.log(
        'reducer::GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_SUCCESS action.payload=' +
          action.payload
      )

      var allModelsFromResp = action.payload
        .map((item) => {
          return { key: item.Model_Name, value: item.Model_Name }
        })
        .sort((a, b) => (a.key >= b.key ? 1 : -1))

      state = {
        ...state,
        allModels: allModelsFromResp,
        loading: false,
      }
      break

    case GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_FAIL:
      console.log(
        'reducer::GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_FAIL action.payload=' +
          action.payload
      )

      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case SEARCH_VEHICLE_BY_VIN:
      console.log(
        'reducer::SEARCH_VEHICLE_BY_VIN action.payload=' + action.payload
      )

      state = {
        ...state,
        year: '',
        color: '',
        make: '',
        model: '',
        vin: '',
        history: [],
        loading: true,
      }
      break

    case SEARCH_VEHICLE_BY_VIN_SUCCESS:
      console.log(
        'reducer::SEARCH_VEHICLE_BY_VIN_SUCCESS action.payload=' +
          action.payload
      )

      var allModel = action.payload.models
        .map((item) => {
          return { key: item.Model_Name, value: item.Model_Name }
        })
        .sort((a, b) => (a.key >= b.key ? 1 : -1))

      //In vehicle order history, filter out imcomplete orders and sort by date
      var orderHist = action.payload.vehicle.order
      orderHist = orderHist.filter(
        (ord) => ord.status == 'Completed' || ord.status == 'WorkReviewed'
      )
      orderHist = orderHist.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))

      console.log(action.payload)
      console.log(allModel)

      state = {
        ...state,
        color: action.payload.vehicle.color,
        year: action.payload.vehicle.year,
        make: action.payload.vehicle.make,
        model: action.payload.vehicle.model,
        allModels: allModel,
        history: orderHist,
        loading: false,
      }
      break

    case SEARCH_VEHICLE_BY_VIN_FAIL:
      console.log(
        'reducer::SEARCH_VEHICLE_BY_VIN_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    default:
      break
  }

  return state
}

export default vehicle
