/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'
import { useFormContext } from 'react-hook-form'
import './dropdown.scss'

const GlistenDropdown = (props) => {
  const methods = useFormContext()
  const {
    formState: { errors },
    setValue,
  } = methods

  useEffect(() => {
    setValue(
      props.formRegistragionName,
      props.value.length > 0 ? props.value : 'DEFAULT'
    )
  }, [props.value])
  console.log(
    props.items.filter(
      (option) => option.key.toUpperCase() == props.value.toUpperCase()
    ).length == 1
      ? props.items
          .filter(
            (option) => option.key.toUpperCase() == props.value.toUpperCase()
          )
          .pop()
          .key.toUpperCase()
      : ''
  )

  return (
    <>
      <Label
        htmlFor='formrow-vin-Input'
        className={
          methods.watch(props.formRegistragionName) == null ||
          methods.watch(props.formRegistragionName) == 'DEFAULT' ||
          methods.watch(props.formRegistragionName) == 'default'
            ? 'glisten-textfield-label glisten-textfield-label-hidden'
            : 'glisten-textfield-label'
        }
      >
        {props.placeholder}
      </Label>

      <select
        disabled={props.nonEditable ? true : false}
        id={props.formRegistragionName}
        name={props.formRegistragionName}
        className={
          methods.watch(props.formRegistragionName) == null ||
          methods.watch(props.formRegistragionName) == 'DEFAULT' ||
          methods.watch(props.formRegistragionName) == 'default'
            ? 'form-select glisten-dropdown-unselected'
            : 'form-select glisten-dropdown'
        }
        style={{
          outline: 0,
          border: 0,
          borderBottom: '1px solid black',
        }}
        defaultValue={
          props.items.filter(
            (option) => option.key.toUpperCase() == props.value.toUpperCase()
          ).length == 1
            ? props.items
                .filter(
                  (option) =>
                    option.key.toUpperCase() == props.value.toUpperCase()
                )
                .pop()
                .key.toUpperCase()
            : ''
        }
        {...methods.register(props.formRegistragionName, {
          pattern: {
            value: /^(?!.*(default|DEFAULT))/,
            message: props.validationMsg,
          },
        })}
      >
        {[{ key: 'default', value: props.placeholder }]
          .concat(props.items)
          .map((val, idx) => (
            <option key={idx} value={val.key.toUpperCase()}>
              {val.value}
            </option>
          ))}
      </select>
      {errors[props.formRegistragionName]?.message && (
        <p style={{ marginBottom: '0rem' }}>
          {errors[props.formRegistragionName]?.message}
        </p>
      )}
    </>
  )
}

GlistenDropdown.propTypes = {
  formRegistragionName: PropTypes.string,
  validationMsg: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.array,
  value: PropTypes.string,
  setValue: PropTypes.func,
  nonEditable: PropTypes.boolean,
}

export default GlistenDropdown
