import React, { useEffect, useState } from 'react'
import { Row, Col, Label } from 'reactstrap'

import GlistenDropdown from '../../../components/GlistenDropdown'
import GlistenOrderHistory from '../../../components/GlistenOrderHistoryTable'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'

import './entervehicleinformation.scss'

import { useFormContext } from 'react-hook-form'
import { sendPageViewEvent } from '../../../firebase'

import LoadingOverlay from 'react-loading-overlay'

import {
  getVehicleByVin,
  getAllVehicleMake,
  getAllVehicleModelByMakeAndYear,
} from 'store/actions'

var yearSearched = ''
var makeSearched = ''

const poundPrefix = '#'

const EnterVehicleInformation = (props) => {
  const {
    onGetVehicleByVin,
    onGetAllVehicleMake,
    onGetAllVehicleModelByMakeAndYear,
  } = props

  useEffect(() => {
    onGetAllVehicleMake()
    sendPageViewEvent('Enter Vehicle Information')
  }, [onGetAllVehicleMake])

  const methods = useFormContext()
  const {
    formState: { errors },
  } = methods

  const [vinSearched, setVinSearched] = useState(false)
  const [modelSearched, setModelSearched] = useState(false)

  const vinEntered =
    methods.watch('vin') != null && methods.watch('vin').length == 6
      ? methods.watch('vin')
      : ''

  const yearEntered =
    methods.watch('year') != null && methods.watch('year') != 'DEFAULT'
      ? methods.watch('year')
      : ''

  const makeEntered =
    methods.watch('make') != null && methods.watch('make') != 'DEFAULT'
      ? methods.watch('make')
      : ''

  if (yearSearched != yearEntered || makeSearched !== makeEntered) {
    setModelSearched(false)
    yearSearched = yearEntered
    makeSearched = makeEntered
  }

  if (
    modelSearched == false &&
    yearEntered.length > 0 &&
    makeEntered.length > 0
  ) {
    onGetAllVehicleModelByMakeAndYear(makeEntered, yearEntered)
    setModelSearched(true)
  }

  if (vinSearched == false && vinEntered.length == 6) {
    onGetVehicleByVin(props.org, props.tenant, vinEntered)
    setVinSearched(true)
  } else if (vinSearched == true && vinEntered.length < 6) {
    setVinSearched(false)
  }

  // const stockOnFocus = (e) => {
  //   if (e.target.value == '') {
  //     e.target.value = '#'
  //   }
  // }

  return (
    <>
      <LoadingOverlay active={props.vinLoading} spinner>
        <Row>
          {/* VIN */}
          <Col lg='3' style={{ marginBottom: '1.25rem' }}>
            <Label
              className={
                methods.watch('vin') == null || methods.watch('vin').length == 0
                  ? 'glisten-textfield-label glisten-textfield-label-hidden'
                  : 'glisten-textfield-label'
              }
            >
              {' '}
              {props.t('Last 6 of VIN')}
            </Label>

            <input
              id='vin'
              name='vin'
              label={props.t('Last 6 of VIN')}
              className='form-control glisten-textfield'
              placeholder={props.t('Last 6 of VIN')}
              defaultValue=''
              {...methods.register('vin', {
                required: props.t('Required'),
                minLength: {
                  value: 6,
                  message: props.t('6 characters required'),
                },
                maxLength: {
                  value: 6,
                  message: props.t('Over 6 char limit'),
                },
                pattern: {
                  value: /^[A-Za-z0-9]+$/,
                  message: props.t('VIN only contains numbers/letters'),
                },
              })}
            />
            {errors.vin?.message && (
              <p style={{ marginBottomBottom: '0rem' }}>{errors.vin.message}</p>
            )}
          </Col>

          {/* STOCK NUMBER */}
          <Col lg='4' style={{ marginBottom: '1.25rem' }}>
            <Label
              className={
                methods.watch('stock') == null ||
                methods.watch('stock').length == 0
                  ? 'glisten-textfield-label glisten-textfield-label-hidden'
                  : 'glisten-textfield-label'
              }
            >
              {' '}
              {props.t('Stock Number (optional)')}
            </Label>

            <input
              id='stock'
              name='stock'
              label={props.t('Stock Number (optional)')}
              className='form-control glisten-textfield'
              placeholder={props.t('Stock Number (optional)')}
              defaultValue=''
              {...methods.register('stock', {
                maxLength: {
                  value: 20,
                  message: props.t('Over 20 char limit'),
                },
                pattern: {
                  value: /^[#][A-Za-z0-9]+$/,
                  message: props.t(
                    'Stock starts with # followed by numbers and/or letters'
                  ),
                },
              })}
              onChange={(e) => {
                const inpt = e.target.value
                e.target.value =
                  poundPrefix +
                  (inpt.indexOf(poundPrefix) == 0
                    ? inpt.substr(poundPrefix.length)
                    : inpt)
              }}
              //onFocus={(e) => stockOnFocus(e)}
            />
            {errors.stock?.message && (
              <p style={{ marginBottomBottom: '0rem' }}>
                {errors.stock.message}
              </p>
            )}
          </Col>

          {/* CUSTOMER TAG */}
          <Col lg='4' style={{ marginBottom: '1.25rem' }}>
            <Label
              className={
                methods.watch('tag') == null || methods.watch('tag').length == 0
                  ? 'glisten-textfield-label glisten-textfield-label-hidden'
                  : 'glisten-textfield-label'
              }
            >
              {' '}
              {props.t('Customer Tag (optional)')}
            </Label>
            <input
              id='tag'
              name='tag'
              label={props.t('Customer Tag (optional)')}
              className='form-control glisten-textfield'
              placeholder={props.t('Customer Tag (optional)')}
              defaultValue=''
              {...methods.register('tag', {
                maxLength: {
                  value: 20,
                  message: props.t('Over 20 char limit'),
                },
                pattern: {
                  value: /^[A-Za-z0-9]+$/,
                  message: props.t(
                    'Customer Tag only contains numbers/letters'
                  ),
                },
              })}
            />
            {errors.tag?.message && (
              <p style={{ marginBottomBottom: '0rem' }}>{errors.tag.message}</p>
            )}
          </Col>
        </Row>

        <Row>
          {/* YEAR */}
          <Col md={3} style={{ marginBottom: '1.25rem' }}>
            <GlistenDropdown
              nonEditable={props.history.length > 0 && vinSearched == true}
              formRegistragionName='year'
              placeholder={props.t('Car Year')}
              items={props.allYears}
              value={vinSearched ? props.year : ''}
              validationMsg={props.t('Select a year')}
            />
          </Col>

          {/* MAKE */}
          <Col md={4} style={{ marginBottom: '1.25rem' }}>
            <GlistenDropdown
              nonEditable={props.history.length > 0 && vinSearched == true}
              formRegistragionName='make'
              placeholder={props.t('Car Make')}
              items={props.allMakes}
              value={vinSearched ? props.make.toUpperCase() : ''}
              validationMsg={props.t('Select a make')}
            />
          </Col>

          {/* MODEL */}
          <Col md={4} style={{ marginBottom: '1.25rem' }}>
            <GlistenDropdown
              nonEditable={props.history.length > 0 && vinSearched == true}
              formRegistragionName='model'
              placeholder={props.t('Car Model')}
              items={props.allModels}
              value={vinSearched ? props.model.toUpperCase() : ''}
              validationMsg={props.t('Select a model')}
            />
          </Col>
        </Row>
        <Row>
          {/* COLOR */}
          <Col md={3} style={{ marginBottom: '1.25rem' }}>
            <GlistenDropdown
              formRegistragionName='color'
              placeholder={props.t('Color')}
              items={props.allColors}
              value={vinSearched ? props.color.toUpperCase() : ''}
              validationMsg={props.t('Select a color')}
            />
          </Col>

          {/* PO Number TAG */}
          <Col lg='4' style={{ marginBottom: '1.25rem' }}>
            <Label
              className={
                methods.watch('poNum') == null ||
                methods.watch('poNum').length == 0
                  ? 'glisten-textfield-label glisten-textfield-label-hidden'
                  : 'glisten-textfield-label'
              }
            >
              {props.t('PO Number (optional)')}
            </Label>
            <input
              id='poNum'
              name='poNum'
              label={props.t('PO Number (optional)')}
              className='form-control glisten-textfield'
              placeholder={props.t('PO Number (optional)')}
              defaultValue=''
              {...methods.register('poNum', {
                maxLength: {
                  value: 20,
                  message: props.t('Over 20 char limit'),
                },
                pattern: {
                  value: /^[A-Za-z0-9]+$/,
                  message: props.t('PO number only contains numbers/letters'),
                },
              })}
            />
            {errors.poNum?.message && (
              <p style={{ marginBottomBottom: '0rem' }}>
                {errors.poNum.message}
              </p>
            )}
          </Col>
        </Row>
        <Row>
          {/* ORDER HISTORY */}
          <Col md={11} style={{ marginBottom: '4rem' }}>
            {props.history.length == 0 && vinSearched == true ? (
              <>
                <h5 className='history-title'>{props.t('Order History')}</h5>
                <h5 className='no-past'>
                  {props.t('No past orders found for this vehicle.')}
                </h5>
              </>
            ) : vinSearched == false ? (
              <></>
            ) : (
              <>
                <h5 className='history-title'>{props.t('Order History')}</h5>
                <GlistenOrderHistory
                  history={vinSearched ? props.history : []}
                />
              </>
            )}
          </Col>
        </Row>
      </LoadingOverlay>
    </>
  )
}

EnterVehicleInformation.propTypes = {
  t: PropTypes.any,
  year: PropTypes.string,
  color: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  history: PropTypes.any,
  allYears: PropTypes.any,
  allColors: PropTypes.any,
  allMakes: PropTypes.any,
  allModels: PropTypes.any,
  onGetVehicleByVin: PropTypes.func,
  onGetAllVehicleMake: PropTypes.func,
  onGetAllVehicleModelByMakeAndYear: PropTypes.func,
  loggedInUsername: PropTypes.string,
  loggedInUserRole: PropTypes.string,
  loggedInUserType: PropTypes.string,
  loggedInUserFirstName: PropTypes.string,
  vinLoading: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ login, vehicle }) => ({
  allYears: vehicle.allYears,
  allColors: vehicle.allColors,
  allMakes: vehicle.allMakes,
  allModels: vehicle.allModels,
  year: vehicle.year,
  color: vehicle.color,
  make: vehicle.make,
  model: vehicle.model,
  history: vehicle.history,
  loggedInUsername: login.username,
  loggedInUserRole: login.role,
  loggedInUserType: login.type,
  loggedInUserFirstName: login.firstName,
  vinLoading: vehicle.loading,
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
})

const mapDispatchToProps = (dispatch) => ({
  onGetVehicleByVin: (org, tenantId, vin) =>
    dispatch(getVehicleByVin(org, tenantId, vin)),
  onGetAllVehicleMake: () => dispatch(getAllVehicleMake()),
  onGetAllVehicleModelByMakeAndYear: (make, year) =>
    dispatch(getAllVehicleModelByMakeAndYear(make, year)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EnterVehicleInformation))
