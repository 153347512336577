import React, { useEffect } from 'react'
import { Row, Col, Label, Table, Media } from 'reactstrap'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { useFormContext } from 'react-hook-form'

import { isToday, formatDateOnly } from '../../../helpers/date_formatter'

import GlistenOrderSummary from '../../../components/GlistenOrderSummary'
import nocarimage from '../../../assets/images/no-car-image.svg'
import { sendPageViewEvent } from '../../../firebase'

const Confirmation = (props) => {
  const methods = useFormContext()

  useEffect(() => {
    sendPageViewEvent('Confirmation')
  }, [])

  console.log(props.orderData)

  const priorityLabel = (idx) => {
    switch (idx) {
      case 1:
        return props.t('WAITER - ')
      case 2:
        return props.t('SPOT DELIVERY - ')
      case 3:
        return props.t('EOD - ')
      case 4:
        return props.t('FILL - ')
      case 5:
        return props.t('APPOINTMENT - ')
      default:
        return ''
    }
  }

  const dueTomrrwMorn = (due_date) => {
    var tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (
      tomorrow.getDate() == due_date.getDate() &&
      tomorrow.getMonth() == due_date.getMonth() &&
      tomorrow.getFullYear() == due_date.getFullYear()
    ) {
      if (due_date.getHours() == 10) {
        if (due_date.getMinutes() <= 29) {
          return true
        } else {
          return false
        }
      } else if (due_date.getHours() < 10) {
        return true
      }
    } else {
      return false
    }
  }

  // Checks if order is due today OR before 10:30AM the following day OR is currently late
  const isDueSoon = (pickup_date) => {
    if (isToday(pickup_date) || dueTomrrwMorn(pickup_date)) {
      return true
    } else {
      return false
    }
  }

  function tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

    if (time.length > 1) {
      time = time.slice(1)
      time[5] = +time[0] < 12 ? props.t(' AM') : props.t(' PM')
      time[0] = +time[0] % 12 || 12
    }
    return time.join('')
  }

  function epochConvert(justdateUTCString, time) {
    //var epoch = Date.parse(justdateUTCString)
    var completeUTC = justdateUTCString
    //completeUTC.setTime(epoch)
    completeUTC.setHours(
      parseInt(time.split(':')[0]),
      parseInt(time.split(':')[1])
    )

    // var hours = 0
    // var minutes = parseInt(time.slice(3,4))
    // completeUTC.setMinutes(minutes)

    // time.charAt(0) == 0
    //   ? (hours = parseInt(time.charAt(1)))
    //   : (hours = parseInt(time.slice(0, 1)))

    // completeUTC.setHours(hours)

    return completeUTC
  }

  console.log(props.orderData)
  return (
    <>
      {/* BODY */}
      <div className='modal-body' style={{ padding: '0rem' }}>
        <Row className='row-margin-fix'>
          <Col
            lg='7'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Row className='row-margin-fix'>
              {/* YEAR + MAKE */}
              <Col xl='12'>
                {props.orderData.year && props.orderData.make ? (
                  <Label
                    className='vehicle-info-label-alt'
                    style={{
                      marginTop: '2.125rem',
                      marginBottom: '0rem',
                      fontWeight: '600',
                    }}
                  >
                    {props.orderData.year} {props.orderData.make}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>
              {/* MODEL */}
              <Col xl='12'>
                {props.orderData && props.orderData.model ? (
                  <Label
                    className='vehicle-info-label-alt'
                    style={{
                      marginBottom: '0rem',
                      fontWeight: '600',
                    }}
                  >
                    {props.orderData.model}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>

              {/* COLOR */}
              <Col xl='12'>
                {props.orderData && props.orderData.color ? (
                  <Label
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '200',
                      fontSize: '1.125rem',
                      letterSpacing: '0.127rem',
                      color: '#333333',
                      marginTop: '1rem',
                      marginBottom: '0rem',
                    }}
                  >
                    {props.t('Color')}
                    {'\u003A'} {props.orderData.color}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>
            </Row>

            {/* VEHICLE IDENTIFIER TABLE */}
            <Row className='row-margin-fix'>
              <Col xl='10' style={{ padding: '0rem' }}>
                <Table
                  striped
                  style={{ marginTop: '2.813rem', marginBottom: '0rem' }}
                >
                  <tbody>
                    <tr style={{ backgroundColor: '#EEEEEE' }}>
                      <td className='thin-table-cell'>{props.t('VIN')}</td>
                      <td
                        className='thin-table-cell'
                        style={{ fontWeight: '200', textAlign: 'right' }}
                      >
                        {props.orderData == null ? null : props.orderData.vin}
                      </td>
                    </tr>
                    <tr>
                      <td className='thin-table-cell'>
                        {props.t('Stock Number')}
                      </td>
                      <td
                        className='thin-table-cell'
                        style={{ fontWeight: '200', textAlign: 'right' }}
                      >
                        {props.orderData.stock == null ||
                        props.orderData.stock == ''
                          ? props.t('N/A')
                          : props.orderData.stock}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: '#EEEEEE' }}>
                      <td className='thin-table-cell'>
                        {props.t('PO Number')}
                      </td>
                      <td
                        className='thin-table-cell'
                        style={{ fontWeight: '200', textAlign: 'right' }}
                      >
                        {props.orderData == null || props.orderData.poNum == ''
                          ? props.t('N/A')
                          : props.orderData.poNum}
                      </td>
                    </tr>
                    <tr>
                      <td className='thin-table-cell'>
                        {props.t('Customer Tag')}
                      </td>
                      <td
                        className='thin-table-cell'
                        style={{ fontWeight: '200', textAlign: 'right' }}
                      >
                        {props.orderData == null || props.orderData.tag == ''
                          ? props.t('N/A')
                          : props.orderData.tag}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            {/* DELIVERY PRIORITY + DATE */}
            <Row className='row-margin-fix'>
              <Col
                lg='10'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                <Label
                  className={
                    props.orderData &&
                    props.orderData.date &&
                    props.orderData.time &&
                    isDueSoon(
                      epochConvert(props.orderData.date, props.orderData.time)
                    )
                      ? 'confirm-red-status-bg'
                      : 'confirm-gray-status-bg'
                  }
                >
                  {props.orderData && priorityLabel(props.orderData.priority)}
                  {props.orderData &&
                    props.orderData.date &&
                    formatDateOnly(new Date(props.orderData.date))}
                  {props.orderData &&
                    props.orderData.time &&
                    tConvert(props.orderData.time)}
                </Label>
              </Col>
            </Row>
          </Col>
          {/* CAR IMAGE */}
          <Col
            lg='4'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Media
              src={nocarimage}
              style={{
                display: 'block',
                maxWidth: '100%',
                height: 'auto',
                width: '100%',
                borderRadius: '8px',
                marginTop: '1rem',
              }}
            ></Media>
          </Col>
        </Row>

        <Row className='row-margin-fix'>
          <Col
            lg='6'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            {props.orderData && props.orderData.services ? (
              <GlistenOrderSummary
                items={
                  methods.watch('services')
                    ? [
                        props.allServices,
                        methods.watch('customs')
                          ? methods.watch('customs')
                          : [],
                      ]
                        .flat()
                        .filter(
                          (item) =>
                            methods.watch('services').indexOf(item.data_key) !==
                            -1
                        )
                    : []
                }
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>

        {props.orderData && props.orderData.note ? (
          <Row className='row-margin-fix'>
            {/* ORDER NOTE */}
            <Col
              lg='10'
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '0.625rem',
                paddingRight: '0rem',
                marginTop: '0.25rem',
                marginBottom: '6.25rem',
              }}
            >
              <Label className='order-note-title'>
                {props.t('Note')}
                {'\u003A'}{' '}
              </Label>
              <Label className='order-note'>{props.orderData.note}</Label>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </div>
    </>
    // <>
    //   <Row>
    //     <Col md={6}>
    //       {props.orderData.vin && props.orderData.stock && (
    //         <GlistenHighlightedTextBox
    //           type='info'
    //           value={`VIN: ${props.orderData.vin} | STOCK: ${props.orderData.stock}`}
    //         />
    //       )}
    //       {props.allYears &&
    //         props.allColors &&
    //         props.orderData.year &&
    //         props.orderData.color && (
    //           <div style={{ marginTop: '2rem' }}>
    //             <h3>
    //               <b>{`${
    //                 props.allYears.filter(
    //                   (item) => item.key == props.orderData.year
    //                 )[0].value
    //               } ${
    //                 props.allModels.filter(
    //                   (item) =>
    //                     item.key.toUpperCase() ==
    //                     props.orderData.model.toUpperCase()
    //                 )[0].value
    //               }`}</b>
    //             </h3>
    //             <h4>
    //               {`Color: ${
    //                 props.allColors.filter(
    //                   (item) =>
    //                     item.key.toUpperCase() ==
    //                     props.orderData.color.toUpperCase()
    //                 )[0].value
    //               }`}
    //             </h4>
    //           </div>
    //         )}
    //       {props.orderData.priority &&
    //         props.orderData.date &&
    //         props.orderData.time && (
    //           <div style={{ marginTop: '3rem' }}>
    //             <GlistenHighlightedTextBox
    //               type='warning'
    //               value={`${
    //                 SERVICE_PRIORITY.filter(
    //                   (it) => it.key == props.orderData.priority
    //                 ).pop().title
    //               } | ${formatDate(props.orderData.date)} | ${tConvert(
    //                 props.orderData.time
    //               )}`}
    //             />
    //           </div>
    //         )}

    //       <div style={{ marginTop: '2rem' }}>
    //         <Controller
    //           control={control}
    //           name='note'
    //           render={({ field }) => (
    //             <GlistenTextInput
    //               placeholder={props.t('Notes')}
    //               setValue={(e) => field.onChange(e)}
    //               value={field.value ? field.value : props.orderData.note}
    //             />
    //           )}
    //         />
    //       </div>
    //     </Col>
    //     <Col md={6}>
    //       <Row></Row>
    //       <br />
    //       <Row>
    //         <Col lg={12}>
    //           {/* <GlistenOrderSummary items={props.selectedServices} /> */}
    //           <GlistenOrderSummary
    //             items={
    //               methods.watch('services')
    //                 ? [
    //                     props.allServices,
    //                     methods.watch('customs')
    //                       ? methods.watch('customs')
    //                       : [],
    //                   ]
    //                     .flat()
    //                     .filter(
    //                       (item) =>
    //                         methods.watch('services').indexOf(item.data_key) !==
    //                         -1
    //                     )
    //                 : []
    //             }
    //           />
    //         </Col>
    //       </Row>
    //     </Col>
    //   </Row>
    // </>
  )
}

Confirmation.propTypes = {
  t: PropTypes.any,
  orderData: PropTypes.any,
  allYears: PropTypes.any,
  allColors: PropTypes.any,
  allMakes: PropTypes.any,
  allModels: PropTypes.any,
  allServices: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  username: PropTypes.string,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ login, vehicle, tenant }) => ({
  allServices: tenant.service_catalog,
  allYears: vehicle.allYears,
  allColors: vehicle.allColors,
  allMakes: vehicle.allMakes,
  allModels: vehicle.allModels,

  tenant: login.tenant,
  username: login.username,
  org: login.org,
  userFeat: login.features,
})

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Confirmation))
